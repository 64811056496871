import React, { useEffect, useState } from 'react';
import "./Countdown.css"

const Countdown = () => {
    const calculateTimeLeft = () => {
      const difference = new Date("2024-06-22 16:30:00") - new Date();
  
      if (difference > 0) {
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        };
      }
      else{
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        }
      }
    };
  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => clearInterval(timer);
    }, []);
  
    return (
      <div id='countdown' className='container countdown_section'>
        <div className='countdown_container'>
          <div className='countdown_item'>
            <div className='countdown_item_number'>{timeLeft.days}</div> 
            <div className='countdown_item_label'>Days</div>
          </div>
          <div className='countdown_item'>
            <div className='countdown_item_number'>{timeLeft.hours}</div> 
            <div className='countdown_item_label'>Hours</div>
          </div>
          <div className='countdown_item'>
            <div className='countdown_item_number'>{timeLeft.minutes}</div> 
            <div className='countdown_item_label'>Minutes</div>
          </div>
          <div className='countdown_item'>
            <div className='countdown_item_number'>{timeLeft.seconds}</div> 
            <div className='countdown_item_label'>Seconds</div>
          </div>
        </div>
      </div>
    );
};
  
export default Countdown;