import './Footer.css';
import React  from 'react';

const Footer = () => {
    
    return (
        <div className='footer_container container'>
            <div className='bottom_container'>
                <div className='bottom_container_content'>
                    <div className='footer_logo'>
                        <div>T</div>
                        <div className='footer_divider'></div>
                        <div>J</div>
                    </div>
                    <div className='footer_info'>Za sva dodatna pitanja možete nas kontaktirati na</div>
                    <div className='footer_phone_numbers'>Juran: 098 918 6515</div>
                    <div className='footer_phone_numbers'>Tea: 091 886 7506</div>
                    <div className='copyright'>©2024 Tea & Juran. Sva prava pridržana.</div>
                </div>
            </div>
        </div>
    );
};

export default Footer;