import React from 'react';
import './App.css';
import Header from './components/header/header';
import HeroSlideshow from './components/heroSlideShow/HeroSlideShow';
import Countdown from './components/countdown/Countdown';
import Invitation from './components/invitation/invitation';
import Checkpoint from './components/checkpoints/Checkpoint';
import Rsvp from './components/RSVP/RSVP';
import Footer from './components/footer/Footer';
import ImageSlider from './image-slider';

function App() {

  return (
    <>
      <Header />
      <HeroSlideshow />
      <Countdown />
      <Invitation />
      <Checkpoint />
      <ImageSlider />
      <div className='footer_quote quotes container'>...with my whole heart, for my whole life...</div>
      <Rsvp />
      <Footer />
    </>
  );
}

export default App;