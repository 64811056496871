import React, { useEffect, useState } from 'react';
import './header.css';
import { useMediaQuery } from 'react-responsive';

const Header = () => {
  const isMediumScreen = useMediaQuery({ maxWidth: '992px' });

  const [scrolled, setScrolled] = useState(false);

  const scrollIntoView = (id) => {
    if(id){
      document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`nav_bar ${scrolled ? 'scrolled' : ''}`}>
      <div className='logo'>
        <div>T</div>
        <div className='divider'></div>
        <div>J</div>
      </div>
      {!isMediumScreen && 
        <div className='header_item_container'>
          <div className='header_item' onClick={() => {scrollIntoView('home')}}>HOME</div>
          <div className='header_item' onClick={() => {scrollIntoView('invitation')}}>INVITATION</div>
          <div className='header_item' onClick={() => {scrollIntoView('locations')}}>LOCATIONS</div>
          <div className='header_item' onClick={() => {scrollIntoView('gallery')}}>GALLERY</div>
        </div>
      }
      <div className='header_item_container'>
        <div className='header_item' onClick={() => {scrollIntoView('rsvp')}}>RSVP</div>
      </div>
    </div>
  );
};

export default Header;