import React from 'react';
import Slider from 'react-slick';
import './image-slider.css';
import { useMediaQuery } from 'react-responsive';

const images = [
  'images/gallery/J&T_152.jpg',
  'images/gallery/J&T_007.jpg',
  'images/gallery/J&T_091.jpg',
  'images/gallery/J&T_116.jpg',
  'images/gallery/J&T_140.jpg',
  'images/gallery/J&T_041.jpg',
  'images/gallery/J&T_167.jpg',
];

const ImageSlider = () => {
  const isMediumScreen = useMediaQuery({ maxWidth: '992px' });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMediumScreen ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    accessibility: true,
    pauseOnHover: false,
    lazyLoad: 'progressive'
  };

  return (
    <div className="image-slider-container container" id="gallery">
      <Slider {...settings}>
        {images.map((image, index) => (
          <img key={`image_${index}`} src={image} alt={`Slide ${index}`} loading="lazy" />
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;