import { useEffect, useState } from "react";
import "./HeroSlideShow.css";
import { useMediaQuery } from "react-responsive";

const IMAGE_CONTAINER = "hero_slideshow_image_container";
const ACTIVE_SLIDE = "slide_active";
const LAST_SLIDE = "slide_last";
const TIME_OUT = 5; // duration of transition in seconds

const itemList = [
  { imgPath: "images/J&T_099_resize.jpg", title: "Tea i Juran 1" },
  { imgPath: "images/J&T_147_resize.jpg", title: "Tea i Juran 2" },
  { imgPath: "images/J&T_113_resize.jpg", title: "Tea i Juran 3" },
  { imgPath: "images/J&T_125.jpg", title: "Tea i Juran 4" },
  { imgPath: "images/J&T_139_resize.jpg", title: "Tea i Juran 5" },
  { imgPath: "images/J&T_029.jpg", title: "Tea i Juran 6" },
  { imgPath: "images/J&T_181_resize.jpg", title: "Tea i Juran 7" },
  { imgPath: "images/J&T_099_resize.jpg", title: "Tea i Juran 8" }
];

const mobileItemList = [
  { imgPath: "images/J&T_003.jpg", title: "Tea i Juran 1" },
  { imgPath: "images/J&T_061.jpg", title: "Tea i Juran 2" },
  { imgPath: "images/J&T_078.jpg", title: "Tea i Juran 3" },
  { imgPath: "images/J&T_089.jpg", title: "Tea i Juran 4" },
  { imgPath: "images/J&T_117.jpg", title: "Tea i Juran 5" },
  { imgPath: "images/J&T_143.jpg", title: "Tea i Juran 6" },
  { imgPath: "images/J&T_146.jpg", title: "Tea i Juran 7" },
  { imgPath: "images/J&T_003.jpg", title: "Tea i Juran 1" }
];

const HeroSlideshow = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const heroItemList = isSmallScreen ? mobileItemList : itemList

  const [currentIndex, setCurrentIndex] = useState(0);
  const imageContainer = document.getElementById(IMAGE_CONTAINER);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % heroItemList.length);
    }, TIME_OUT * 1000);

    return () => clearInterval(interval);
  }, [heroItemList]);

  if (imageContainer !== null) {
    setTimeout(
      () => {
        if (currentIndex === heroItemList.length - 1) {
          imageContainer.classList.add(LAST_SLIDE);
          setCurrentIndex(0);
        } else if (currentIndex === 0) {
          imageContainer.classList.remove(LAST_SLIDE);
        }
      },
      currentIndex === 0 ? 100 : 1100
    );
  }

  return (
    <div className="block_slider_fixed" id="home">
      <div className="block_content">
          <div id="hero_slideshow" className={isSmallScreen ? "small_screen" : ""}>
            <div
              id={IMAGE_CONTAINER}
              className={ACTIVE_SLIDE}
              style={{
                width: `${heroItemList.length * 100}vw`,
                transform: `translateX(${-currentIndex * 100}vw)`,
              }}
            >
              {heroItemList.map((item, index) => (
                <div className="slide" key={`slide` + index}>
                  <img
                    key={index}
                    src={item.imgPath}
                    alt={`hero ${item.name} ${index + 1}`}
                    loading="lazy"
                  />
                  {/* <div className="slide_overlay"></div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
    </div>
  );
};

export default HeroSlideshow;
