import React from 'react';
import './Checkpoint.css';

const Checkpoint = () => {
    return (
        <div className='main_container' id="locations">
            <div className='invitation-info-container'>
                <div className='names'>TEA & JURAN</div>
                <div className='invitation-info-header'>Pozivamo vas na svoje vjenčanje</div>
                <div className='date'>22. lipnja 2024.</div>
            </div>
            <div className='location_container'>
                <div className='details_container'>
                    <div className='pretitle'>Cheers</div>
                    <a href='https://maps.app.goo.gl/HeZzPk8dJcgdHWTF7' target='_blank' className='title'>HOTEL CORA</a>
                    <div className='location_details_description'>Pomozi nam izbrisati nervozu i nazdravi s nama na krovu Hotela Cora.</div>
                    <div className='location_time'>17:00 - 18:30</div>
                </div>
                <div className='details_container'>
                    <div className='pretitle'>We do</div>
                    <a href='https://maps.app.goo.gl/ceHBjMXuCqkJMwNf8' target='_blank' className='title'>SV. ANTE POLJUD</a>
                    <div className='location_details_description'>Svoje sudbonosno 'DA' izreći ćemo u Samostanu Sv. Ante na Poljudu.</div>
                    <div className='location_time'>19:00 - 20:00</div>
                </div>
                <div className='details_container'>
                    <div className='pretitle'>Dinner</div>
                    <a href='https://maps.app.goo.gl/6NMyhsnNCzy54eoV6' target='_blank' className='title'>HOTEL SALONA PALACE</a>
                    <div className='location_details_description'>Zapleši s nama uz ritmove grupe Boss u dvorani Hotela Salona Palace.</div>
                    <div className='location_time'>20:30 - „Nećemo do zore“</div>
                </div>
            </div>
        </div>
    );
};
  
export default Checkpoint;