import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "./RSVP.css";

const Rsvp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sheetResponse, setSheetResponse] = useState({
    status: null,
    message: null,
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    numberOfGuests: "",
    numberOfChildren: "",
    others: "",
  });

  const toggleLoading = (_isLoading) => {
    if (_isLoading) {
      setIsLoading(true);
      document.body.style.overflow = "hidden";
    } else {
      setIsLoading(false);
      document.body.style.overflow = "auto";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    toggleLoading(true);
    const url =
      "https://script.google.com/macros/s/AKfycbzzjE2Mh9CdIJYc2u2AneTjjy3R2huq-Af8SIoYeuNoSfBOiFETrYDi0Jw6jtY8VibRYw/exec";
    try {
      const response = await fetch(url, {
        redirect: "follow",
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      });

      setFormData({
        firstName: "",
        lastName: "",
        numberOfGuests: "",
        numberOfChildren: "",
        others: "",
      });

      if (response.status === 200) {
        setSheetResponse({
          status: 200,
          message: "Hvala Vam na vašoj prijavi i veselimo se Vašem dolasku!",
        });
      } else {
        setSheetResponse({
          status: 500,
          message:
            "Nismo uspjeli spremiti vašu prijavu. Probajte ponovno ili nam se javite.",
        });
      }
      console.log(response);
    } catch (error) {
      console.error("Error sending data:", error);
    }

    toggleLoading(false);
  };

  return (
    <>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <div className="rvsp_container container" id="rsvp">
          <img
            className="rvsp_image"
            src="/images/J&T_172.jpg"
            alt="ImageOne"
          />
          <div className="components_container">
            <div className="rvsp_header">
              <div className="rvsp_title">RSVP</div>
              <div className="rvsp_info">
                Molimo potvrdite svoj dolazak do 01.06.2024.
              </div>
            </div>
            <div className="rvsp_form_container">
              <div className="rvsp_double_form">
                <TextField
                  onChange={(event) => {
                    setFormData((oldData) => {
                      return { ...oldData, firstName: event.target.value };
                    });
                  }}
                  required
                  id="firstName"
                  label="Ime"
                  variant="standard"
                  size="small"
                  value={formData.firstName}
                />
                <TextField
                  onChange={(event) => {
                    setFormData((oldData) => {
                      return { ...oldData, lastName: event.target.value };
                    });
                  }}
                  required
                  id="lastName"
                  label="Prezime"
                  variant="standard"
                  size="small"
                  value={formData.lastName}
                />
              </div>
              <div className="rvsp_double_form">
                <TextField
                  type="number"
                  onChange={(event) => {
                    setFormData((oldData) => {
                      return { ...oldData, numberOfGuests: event.target.value };
                    });
                  }}
                  required
                  id="guests"
                  label="Broj odraslih"
                  variant="standard"
                  size="small"
                  value={formData.numberOfGuests}
                />
                <TextField
                  type="number"
                  onChange={(event) => {
                    setFormData((oldData) => {
                      return {
                        ...oldData,
                        numberOfChildren: event.target.value,
                      };
                    });
                  }}
                  required
                  id="kids"
                  label="Broj djece"
                  variant="standard"
                  size="small"
                  value={formData.numberOfChildren}
                />
              </div>
              <div className="rvsp_single_form" style={{ marginTop: "20px" }}>
                <TextField
                  onChange={(event) => {
                    setFormData((oldData) => {
                      return { ...oldData, others: event.target.value };
                    });
                  }}
                  className="rvsp_others"
                  label="Imena i prezimena ostalih gostiju"
                  required
                  multiline
                  rows={4}
                  value={formData.others}
                />
              </div>
              <Button className="sent_button" variant="contained" type="submit">
                Pošalji
              </Button>
              {sheetResponse.status > 0 && (
                <Alert
                  severity={sheetResponse.status === 200 ? "success" : "error"}
                >
                  {sheetResponse.message}
                </Alert>
              )}
            </div>
          </div>
        </div>
      </form>
      <Backdrop sx={{ color: "#fff", zIndex: 100 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Rsvp;
