import React from 'react';
import './invitation.css';

const Invitation = () => {
    return (
        <div className='invitation-container container' id="invitation">
            <div className='quotes'>...the next chapter of their story begins...</div>
            <div className='introduction-container'>
                <div>ZA DAN KADA PJESMOM NAS BUDE</div>
                <div>ZA NAS I ZA NAJDRAŽE LJUDE</div>
                <div>ZA DAN KAD SE OSTVARE SNOVI</div>
                <div>I SVI TVOJI BUDU MOJI</div>
                <div>A SVI MOJI BUDU TVOJI</div>
            </div>
        </div>
    );
};
  
export default Invitation;